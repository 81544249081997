import { Link, graphql, useStaticQuery } from 'gatsby'

import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import React from 'react'

const ContactPrivacy = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="contact-privacy" lang="ja" />
        <title>{`お問い合わせにおける個人情報の取扱について | ${title}`}</title>
      </Helmet>

      <div className="common-header clearfix">
        <h1>お問い合わせにおける個人情報の取扱について</h1>
        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /<li>お問い合わせにおける個人情報の取扱について</li>
        </div>
      </div>

      <div className="main-wrapper">
        <div className="row">
          <div className="container">
            <h5>事業者の氏名または名称</h5>
            株式会社アイテック
            <h5 className="following-title">個人情報の利用目的</h5>
            当サイトで取得した個人情報は、お問合せ対応のためにのみ利用致します。
            <h5 className="following-title">
              個人情報を入力するにあたっての注意事項
            </h5>
            ご記入頂きました内容に誤りがありますと、お返事できない場合がございますので、ご入力の際にはご注意願います。
            <br />
            尚、個人情報を当社に与えることについてご本人様の任意性は存在しますが、与えられなかった場合は、当該利用目的を達成することができず、ご本人様が期待された結果が得られないことがあります。
            <h5 className="following-title">個人情報の第三者提供について</h5>
            ご本人の同意がある場合または法令に基づく場合を除き、取得した個人情報を第三者に提供することはありません。
            <h5 className="following-title">個人情報の取扱の委託について</h5>
            当社では、利用目的の達成に必要な範囲内において、他の事業者へ個人情報を委託することがございます。その場合は、個人情報保護体制が整備された委託先を選定するとともに、個人情報保護に関する契約を締結致します。
            <h5 className="following-title">個人情報保護管理者について</h5>
            次の者を当社の個人情報保護管理者といたします。
            <br />
            <br />
            【所属】
            <br />
            株式会社アイテック
            <br />
            【個人情報保護管理者】
            <br />
            運営管理部 部長
            <br />
            【住所】
            <br />
            〒112-0004 東京都文京区後楽2丁目1番3号　ＮＳ飯田橋ビル1階
            <br />
            【連絡先】
            <br />
            TEL： 03-5802-6871　FAX： 03-5802-6872
            <br />
            E-Mail：pms@i-tech-corp.co.jp
            <br />
            【受付時間】
            <br />
            10:00～17:00 但し、土・日・祝日・年末年始は休業となります。
            <h5 className="following-title">
              個人情報に関する開示等のご請求窓口について
            </h5>
            次の者を当社の個人情報保護管理者の代理人とし、開示等のご請求に対応します。
            <br />
            <br />
            【所属】
            <br />
            株式会社アイテック
            <br />
            【開示等の問い合わせ窓口】
            <br />
            個人情報保護相談窓口担当者
            <br />
            【住所】
            <br />
            〒112-0004 東京都文京区後楽2丁目1番3号　ＮＳ飯田橋ビル1階
            <br />
            【連絡先】
            <br />
            TEL： 03-5802-6871　FAX： 03-5802-6872
            <br />
            E-Mail：pms@i-tech-corp.co.jp
            <br />
            【受付時間】
            <br />
            10:00～17:00 但し、土・日・祝日・年末年始は休業となります。
            <h5 className="following-title">プライバシーポリシー</h5>
            <p>
              当社ホームページの
              <Link to={`/policy`}>プライバシーポリシー</Link>
              をご覧ください。
            </p>
            <h5 className="following-title">個人情報の取扱について</h5>
            <p>
              当社の個人情報取扱の詳細については
              <Link to={`/privacy`}>個人情報の取扱について</Link>
              ページにてご確認ください。
            </p>
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPrivacy
